<template>
  <section class="refund-policy">
    <h1 class="title">Опис порядку повернення товарів або відмови від послуг та повернення коштів</h1>

    <div class="container container--min">
      <div class="paragraph">
        Політика повернення коштів<br>
        Щоб дізнатися більше про терміни та правила повернення коштів, ознайомтеся з наведеною нижче інформацією.
        Крім того, ми ретельно розглядаємо порушення Умов використання та не несемо зобов'язань щодо повернення
        коштів користувачам продукту, які їх порушили, навіть якщо вони запросили повернення протягом встановленого
        терміну.<br>
        Якщо ви скасували сплачену реєстрацію на придбання послуг, ТОВ «АЛОКА АС» повністю поверне вам кошти протягом
        14 днів з моменту оплати. Якщо ви заздалегідь зареєструвалися та оплатили послуги, ТОВ «АЛОКА АС» повністю
        поверне вам кошти протягом 14 днів після встановлення дати початку дії договору. Щоб уникнути різночитань:
        ви не маєте права на повернення коштів навіть протягом 14 днів після оплати і початку використання продукту.
        Незалежно від вищевикладеного, для певних тарифів можуть встановлюватися скорочені терміни повернення.
        Скорочений термін повернення буде вказано під час укладання договору.
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RefundPolicy'
}
</script>

<style lang="scss" scoped>
$refund: "refund-policy";
.#{$refund} {
  padding: 60px 0;
}
</style>